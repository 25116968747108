import { useMatches } from '@remix-run/react'

import type { RootLoaderData } from '~/root'

/**
 * Used to get data of `root` route, for other routes use `useRouteData(route)` hook from remix-utils.
 */
export const useRootData = (): RootLoaderData => {
  return useMatches().find((match) => match.id === 'root')?.data as RootLoaderData
}
