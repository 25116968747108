import clsx from 'clsx'
import type { ReactNode } from 'react'
import { cleanPage, PageViewer, useReactBricksContext } from 'react-bricks'
import { Theme } from 'react-daisyui'

import { useRootData } from '~/hooks/useRootData'

interface LayoutProps {
  children?: ReactNode
  className?: string
}

const theme = 'artisancove'

const Layout: React.FC<LayoutProps> = ({ className, children }) => {
  const { footerPage, headerPage } = useRootData()
  const { pageTypes, bricks } = useReactBricksContext()

  const headerOk = headerPage ? cleanPage(headerPage, pageTypes, bricks) : null
  const footerOk = footerPage ? cleanPage(footerPage, pageTypes, bricks) : null

  return (
    <Theme dataTheme={theme} className="relative flex flex-col flex-1 min-h-screen antialiased">
      {headerOk ? (
        <PageViewer page={headerOk} />
      ) : (
        <div>Header failed to load. Make sure a entity named 'header' exists in admin.</div>
      )}
      <main className={clsx(className, 'min-h-[90vh] w-full')}>{children}</main>
      {footerOk ? (
        <PageViewer page={footerOk} />
      ) : (
        <div>Footer failed to load. Make sure a entity named 'footer' exists in admin.</div>
      )}
    </Theme>
  )
}

export default Layout
